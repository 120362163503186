const colors = {
    malachite: 'rgba(50,232,128,1)', // #32e880
    sauvignon: 'rgba(250,245,242,1)', // #FAF5F2
    swamp: 'rgba(37,48,49,1)', // #253031
    white: 'rgba(255,255,255,1)',
}

const Theme = {
    brand: {
        primary: `${colors.malachite}`,
    },
    ui: {
        primary: `${colors.swamp}`,
        inverse: `${colors.sauvignon}`
    },
    text: {
        primary: `${colors.sauvignon}`,
        brand: `${colors.malachite}`,
        hover: `${colors.malachite}`,
        inverse: `${colors.swamp}`,
    },
    inverse: {
        primary: `${colors.white}`,
    },
    field: {
        background: `${colors.white}`,
        text: `${colors.swamp}`,
    },
    support: `${colors.white}`,
}

export default Theme